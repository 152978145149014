<template>
  <div class="wh-container">
    <div class="header">
      <p class="title">Configurações da consulta</p>
    </div>

    <div class="container-data">
      <div class="tabs">
        <span
          v-can="'ConfConAten1'"
          class="tab"
          :class="{ active: currentTabIndex == 'consultas' }"
          @click="currentTabIndex = 'consultas'"
        >
          Atendimentos personalizados
        </span>
        <span
          v-can="'ConfConReg1'"
          class="tab"
          :class="{ active: currentTabIndex == 'registros' }"
          @click="currentTabIndex = 'registros'"
        >
          Registros padrões
        </span>
      </div>
      <div class="body flex-fill">
        <MyCustomAppointmentsTab
          v-if="currentTabIndex === 'consultas'"
          :clinic="clinic"
          :person="person"
        />
        <MyCustomRecordTab
          v-if="currentTabIndex === 'registros'"
          :clinic="clinic"
          :person="person"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'

export default {
  metaInfo: {
    title: 'Eyecare - Configurações da consulta'
  },
  name: 'CustomAppointmentsConfig',
  components: {
    MyCustomAppointmentsTab: () => import('./MyCustomAppointmentsTab'),
    MyCustomRecordTab: () => import('./MyCustomRecordTab')
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      person: getCurrentUser(),
      currentTabIndex: this.$route.params.tab || 'consultas'
    }
  }
}
</script>

<style lang="scss" scoped>
.wh-container {
  height: 100%;
  padding: 30px 24px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 24px;
  }
  .title {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);
  }

  .container-data {
    width: 100%;
    background-color: var(--neutral-000);
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    .tabs {
      height: 72px;
      border-bottom: 1px solid var(--neutral-200);
      display: flex;
      align-items: center;

      .tab {
        font-weight: 600;
        font-size: 16px;
        color: var(--type-active);
        padding: 22px 24px;
        cursor: pointer;
        border-bottom: 3px solid var(--neutral-000);

        &.active {
          color: var(--orange);
          border-bottom: 4px solid var(--orange);
        }
      }
    }

    .body {
      padding: 24px;

      .title {
        font-family: 'Red Hat Display';
        font-weight: 700;
        font-size: 18px;
        color: var(--dark-blue);
        margin-bottom: 24px;
      }

      .table {
        width: 100%;
        display: table;

        thead {
          th {
            font-weight: 700;
            font-size: 14px;
            color: var(--type-active);
            border-top: 0;
            padding: 4px 0;
            border-bottom: 2px solid var(--neutral-300);
          }
        }

        tbody {
          tr {
            border-bottom: 1px dashed var(--neutral-300);
          }

          td {
            font-weight: 400;
            font-size: 16px;
            color: var(--type-active);
            border-top: 1px dashed var(--neutral-300);
            padding: 16px 0;

            .more-with-menu {
              width: 24px;
              height: 28px;
              position: relative;
              display: inline-block;

              .more-icon {
                width: 24px;
                height: 24px;
                stroke: var(--neutral-500);
                transition: all 0.5s;
                cursor: pointer;
              }

              .menu {
                width: 233px;
                position: absolute;
                top: 28px;
                right: 0;
                background: var(--neutral-000);
                border: 1px solid var(--neutral-200);
                box-sizing: border-box;
                box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
                  0px 4px 10px -8px rgba(12, 29, 89, 0.2);
                border-radius: 8px;
                padding: 24px;
                opacity: 0;
                transition: all 0.5s;
                flex-direction: column;
                align-items: start;
                display: none;

                .mtb-12 {
                  margin: 12px 0;
                }

                .btn {
                  font-weight: 600;
                  margin-bottom: 16px;

                  &.remove {
                    color: var(--states-error);
                  }
                }
              }

              &:hover {
                .more-icon {
                  transform: rotate(90deg);
                }

                .menu {
                  opacity: 1;
                  display: flex;
                  z-index: 10;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
